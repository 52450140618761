import axios from "axios";
import fetch from "isomorphic-unfetch";
import cookie from "js-cookie";
import moment from "moment";
import type { NextPageContext } from "next";
import nextCookie from "next-cookies";

import Router from "next/router";
import queryString from "querystring";
import { Component } from "react";
import TagManager from "react-gtm-module";
import { v4 as uuid } from "uuid";
import { API } from "../api";
import type { EventImageCarouselMedia } from "../components/EventImageCarousel/types/EventImageCarouselMedia";
import MLDialog from "../components/MLDialog";
import NoSSR from "../components/NoSSR";
import type { IUser } from "../components/eventCard";
import type { IEventDetail } from "../features/event/single/view/interfaces/IEventDetail";
import { queryClient } from "./ReactQuery";

const flatten = require("arr-flatten");

const REDICT_ACCESS_LOGIN = "/access/login";

export const isProduction = () => {
	const env = process.env.NODE_ENV;

	return env === "production";
};

export interface Context extends NextPageContext {
	// any modifications to the default context, e.g. query types
}

export const getTokenFromCookies = (ctx: Context) => {
	const { nowr_token } = (ctx && nextCookie(ctx)) || {
		nowr_token: cookie.get("nowr_token"),
	};
	return nowr_token;
};

export const redirectTo = (ctx: Context, location: string) => {
	if (ctx?.res && !ctx?.res?.headersSent) {
		const { res } = ctx;

		res?.writeHead?.(302, { Location: location });
		res?.end?.();
	} else {
		try {
			Router?.replace?.(location);
		} catch (e) {
			console.log(e);
			if (typeof window !== "undefined") {
				try {
					window.location.href = location;
				} catch (err) {
					console.log("ERR ROUTER", err);
				}
			}
		}
	}
};

export const redirectIfUnderMaintenance = (ctx: Context) => {
	// if (UNDER_MAINTEINANCE == "true") {
	//   redirectTo(ctx, "/maintenance");
	// }
};

export const getFileExtensionByEnvironment = () => {
	const env = process.env.NODE_ENV;
	return !env || env === "production" ? ".js" : ".ts";
};

export const redirectToLoginIsoMorph = (ctx?: Context) => {
	if (ctx?.req) {
		ctx.res?.writeHead(302, {
			Location: `${REDICT_ACCESS_LOGIN}?${queryString.stringify({ redirect_url: ctx.asPath })}`,
		});
		ctx.res?.end();
		return;
	}

	Router?.push?.({
		pathname: REDICT_ACCESS_LOGIN,
		query: { redirect_url: ctx?.asPath },
	});
	// logout();
	return false;
};

export const nextAuthUser = (ctx: Context) => {
	const { nowr_token: token } = (ctx && nextCookie(ctx)) || {
		nowr_token: cookie.get("nowr_token"),
	};

	if (!token) {
		return redirectToLoginIsoMorph(ctx);
	}

	return token;
};

export const logout = (callback?: any) => {
	callback && callback();
	cookie.remove("nowr_token");
	window.localStorage.setItem("logout", Date.now().toString());
	queryClient.clear();
	Router.push(REDICT_ACCESS_LOGIN);
};

const getDisplayName = (Component) => Component.displayName || Component.name || "Component";

export type withAuthSyncOptions = {
	hostAccessOnly?: boolean;
};

export const withAuthSync = (WrappedComponent: any, options?: { hostAccessOnly?: boolean; ssr?: boolean }) =>
	class extends Component {
		static displayName = `withAuthSync(${getDisplayName(WrappedComponent)})`;

		static async getInitialProps(ctx: Context) {
			const token = nextAuthUser(ctx);

			if (token == false) {
				console.log("ctx", ctx);
				return {};
			}

			if (options?.hostAccessOnly === true) {
				const user: any = await API.methods.getSimpleUser(token);
				if (!user?.isHost) {
					redirectTo(ctx, "/account/get-host");
					return {};
				}
			}

			const componentProps = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));

			return { ...componentProps, token };
		}

		// New: We bind our methods
		constructor(props) {
			super(props);
			this.syncLogout = this.syncLogout.bind(this);
		}

		// New: Add event listener when a restricted Page Component mounts
		componentDidMount() {
			window.addEventListener("storage", this.syncLogout);
		}

		// New: Remove event listener when the Component unmount and
		// delete all data
		componentWillUnmount() {
			window.removeEventListener("storage", this.syncLogout);
			window.localStorage.removeItem("logout");
		}

		// New: Method to redirect the user when the event is called
		syncLogout(event) {
			if (event.key === "logout") {
				console.log("logged out from storage!");
				Router.push(REDICT_ACCESS_LOGIN);
			}
		}

		render() {
			if (options?.ssr === false)
				return (
					<NoSSR>
						<WrappedComponent {...this.props} />;
					</NoSSR>
				);

			return <WrappedComponent {...this.props} />;
		}
	};

export const placeholderUserUrl = "/images/empty-profile.png"; //"https://s3.eu-west-1.amazonaws.com/nowrmedia-test/assets/defaultuser.png";
export const placeholderUserS3Url = "https://s3.eu-west-1.amazonaws.com/nowrmedia-test/assets/defaultuser.png";
export const buildApiWithQueryParams = (url: string, queryParams: any) => {
	const params = queryString.stringify(queryParams);

	return `${url}?${params}`;
};

export function getFullNameFrom(user?: { firstName?: string; lastName?: string; alias?: string }) {
	return `${user?.firstName || "Nome"} ${user?.lastName || "Cognome"}`.trim();
}

export function getFullNameFromOrAlias(user?: { firstName?: string; lastName?: string; alias?: string }) {
	return `${user?.firstName ?? ""} ${user?.lastName ?? ""}`.trim() || user?.alias || "";
}

export function trimStringToMax(value: string | undefined, max: number) {
	if (!value || typeof value !== "string") return value;
	if (value.length < max) {
		return value;
	}

	return value?.substr?.(0, max) + "...";
}

export async function fasterFetch(input: RequestInfo, options?: RequestInit, ctx?: Context) {
	try {
		const res = await fetch(input, options);

		if (res.status == 401 && ctx) {
			redirectTo(ctx, "/access/login");
			return;
		}

		if (res.status >= 300 || res.status < 200) return null;

		return await res.json();
	} catch (error) {
		// Router.push({ pathname: REDICT_ACCESS_LOGIN, query: { redirect_url: ctx.asPath } });
		console.log("ERORROROROROORE 💚💚💚💚💚", error);
		// Router.push({ pathname: REDICT_ACCESS_LOGIN, query: { redirect_url: Router.asPath } });
	}
}

export const uploadToS3 = async (token: string, file: any, fileExtension?: string): Promise<string | null> => {
	const S3 = (await import("aws-sdk/clients/s3")).default;
	const config = (await import("aws-sdk/global")).config;

	const s3credentials = await fasterFetch(API.aws.s3credentials, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"X-Auth-Token": token,
		},
	});

	if (!s3credentials) return null;

	const splittedFilename = (file?.name ?? uuid() + (fileExtension ?? ".png")).split(".");

	const extension = splittedFilename[splittedFilename.length - 1];

	config.update({
		credentials: {
			accessKeyId: s3credentials.accessKeyId,
			secretAccessKey: s3credentials.secretAccessKey,
			sessionToken: s3credentials.sessionToken,
		},
	});

	const splittedBucket = s3credentials.bucket.split("/");

	const bucketName = splittedBucket[splittedBucket.length - 1];

	var upload = new S3.ManagedUpload({
		params: {
			Bucket: bucketName, //s3credentials.bucket,
			Key: `${uuid()}.${extension}`,
			Body: file,
			ACL: "public-read",
		},
	});

	const response = await upload.promise();

	return response.Location.replace('"', "");
};

export const loadScript = (url: string) =>
	new Promise((resolve, reject) => {
		let ready = false;
		if (!document) {
			reject(new Error("Document was not defined"));
		}
		const tag = document.getElementsByTagName("script")[0];
		const script = document.createElement("script");

		script.type = "text/javascript";
		script.src = url;
		script.async = true;

		(script as any).onreadystatechange = () => {
			// if (!ready && (!this.readyState || this.readyState === 'complete')) {
			if (!ready) {
				ready = true;
				resolve(script);
			}
		};
		script.onload = (script as any).onreadystatechange;

		script.onerror = (msg) => {
			console.log(msg);
			reject(new Error("Error loading script."));
		};

		script.onabort = (msg) => {
			console.log(msg);
			reject(new Error("Script loading aboirted."));
		};

		if (tag.parentNode != null) {
			tag.parentNode.insertBefore(script, tag);
		}
	});

export const flattenPromisesAll = async (promises: Promise<any>[]) => {
	return await Promise.all(promises)
		.then((values) => flatten(values))
		.catch((errors) => console.log("FLATTEN PROMISE ALL ERRORS", errors));
};

export const trunkateIfLongerThan = (text: string, maxLength: number) => {
	if (text?.length <= maxLength ?? 0) return text;
	const trunked = (text ?? "").substring(0, maxLength ?? 0);

	return `${trunked} ...`;
};

export const urlify = (text: string) => {
	var urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

	var array = text.split(" ");
	let array1 = "";

	for (var i = 0; i < array.length; i++) {
		array1 +=
			array[i].replace(urlRegex, function (url) {
				return '<a target="_blank" href="' + url + '">' + url + "</a>";
			}) + " ";
	}

	// console.log("URGENTE", array1);
	return array1;
};

export const sendGTMUserLayer = (user: IUser) => {
	if (!user) return;

	const userLayer = {
		dataLayer: {
			user_ID: user?.id,
			user_name: `${user?.firstName} ${user?.lastName}`,
			user_type: user?.iban && user?.invoiceAddress && user?.invoiceCap ? "BUSINESS" : "STANDARD",
			user_level: user?.userState,
			followers: user?.follower || 0,
			following: user?.followed || 0,
		},
	};

	// console.log("USER_LAYER", userLayer);

	TagManager.dataLayer(userLayer);
};

export function isValidIBANNumber(input) {
	var CODE_LENGTHS = {
		AD: 24,
		AE: 23,
		AT: 20,
		AZ: 28,
		BA: 20,
		BE: 16,
		BG: 22,
		BH: 22,
		BR: 29,
		CH: 21,
		CR: 21,
		CY: 28,
		CZ: 24,
		DE: 22,
		DK: 18,
		DO: 28,
		EE: 20,
		ES: 24,
		FI: 18,
		FO: 18,
		FR: 27,
		GB: 22,
		GI: 23,
		GL: 18,
		GR: 27,
		GT: 28,
		HR: 21,
		HU: 28,
		IE: 22,
		IL: 23,
		IS: 26,
		IT: 27,
		JO: 30,
		KW: 30,
		KZ: 20,
		LB: 28,
		LI: 21,
		LT: 20,
		LU: 20,
		LV: 21,
		MC: 27,
		MD: 24,
		ME: 22,
		MK: 19,
		MR: 27,
		MT: 31,
		MU: 30,
		NL: 18,
		NO: 15,
		PK: 24,
		PL: 28,
		PS: 29,
		PT: 25,
		QA: 29,
		RO: 24,
		RS: 22,
		SA: 24,
		SE: 24,
		SI: 19,
		SK: 24,
		SM: 27,
		TN: 24,
		TR: 26,
	};
	var iban = String(input)
			.toUpperCase()
			.replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
		code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
		digits;
	// check syntax and length
	if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
		return false;
	}
	// rearrange country code and check digits, and convert chars to ints
	digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
		return (letter.charCodeAt(0) - 55).toString();
	});
	// final check
	return mod97(digits);
}
function mod97(string) {
	var checksum = string.slice(0, 2),
		fragment;
	for (var offset = 2; offset < string.length; offset += 7) {
		fragment = String(checksum) + string.substring(offset, offset + 7);
		checksum = parseInt(fragment, 10) % 97;
	}
	return checksum;
}

export function getNextDateRepeatableEvent(event: IEventDetail) {
	const { startDate, repeatOnDays } = event;

	const momentStartDate = moment(startDate);
	const today = moment().hour(momentStartDate.hour()).minute(momentStartDate.minute());

	const upperDate = today.isBefore(momentStartDate) ? momentStartDate : today;

	const availableDates = (repeatOnDays?.split("_") || [])
		.map((x, i) => (x == "1" ? i + 1 : null))
		.filter((x) => x != undefined);

	const lowerDay = upperDate.day();

	//@ts-expect-error
	const nextAvailableDay = availableDates.find((x) => x >= lowerDay);

	//@ts-expect-error
	let nextDate = upperDate.add(nextAvailableDay - lowerDay, "days");

	return nextDate;
}

export function featureInWip() {
	MLDialog.showModal(
		"Ci stiamo lavorando!",
		"Questa funzionalità è in via di sviluppo!",
		"Torna a trovarci ogni giorno per vedere quando sarà disponibile.",
		{ noHeader: true, positiveText: "OK! Lo farò" }
	);
}

export class cloneable {
	public static deepCopy<T>(source: T): T {
		return Array.isArray(source)
			? source.map((item) => this.deepCopy(item))
			: source instanceof Date
			? new Date(source.getTime())
			: source && typeof source === "object"
			? Object.getOwnPropertyNames(source).reduce((o, prop) => {
					Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
					o[prop] = this.deepCopy((source as { [key: string]: any })[prop]);
					return o;
			  }, Object.create(Object.getPrototypeOf(source)))
			: (source as T);
	}
}

export function sleep(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function handlePicturesOfEvent(props: {
	token: string;
	event: IEventDetail;
	currentImages: IEventDetail["pictures"];
	newImages: EventImageCarouselMedia[];
}) {
	const promises: Promise<any>[] = [];

	const prevIds = props.currentImages.map((x) => x.id).filter(Boolean);
	const newIds = props.newImages.map((x) => x.id).filter(Boolean);

	const deleted = prevIds.filter((x) => !newIds.includes(x));

	if (deleted.length > 0 && newIds.length > 0) {
		deleted.forEach((imageId) => {
			promises.push(
				fetch(`${API.picture.delete}/${imageId}`, {
					method: "DELETE",
					headers: { "X-Auth-Token": props.token, "Content-Type": "application/json" },
				})
			);
		});
	}

	if (props.newImages.length > 0) {
		props.newImages.forEach((image) => {
			if (image.id) {
				promises.push(
					fetch(API.picture.edit, {
						method: "PUT",
						headers: { "X-Auth-Token": props.token, "Content-Type": "application/json" },
						body: JSON.stringify({
							id: image.id,
							url: image.contentType === "PHOTO" ? image.mediaPreviewUrl : image.videoThumbnailUrl,
							mediaUrl: image.contentType === "VIDEO" ? image.mediaPreviewUrl : undefined,
							mediaType: image.contentType,
							event: { id: props.event.id },
							main: image.isCover,
						}),
					}).then((res) => res.json())
				);
			} else {
				promises.push(
					fetch(API.picture.create, {
						method: "POST",
						headers: { "X-Auth-Token": props.token, "Content-Type": "application/json" },
						body: JSON.stringify({
							url: image.contentType === "PHOTO" ? image.mediaPreviewUrl : image.videoThumbnailUrl,
							mediaUrl: image.contentType === "VIDEO" ? image.mediaPreviewUrl : undefined,
							mediaType: image.contentType,
							event: { id: props.event.id },
						}),
					}).then((res) => res.json())
				);
			}
		});
	}
	Promise.all(promises)
		.then((resp) => {
			let main = props.newImages.filter((x) => x.isCover)?.[0];
			let id = main?.id;

			if (!id) {
				main = resp.filter((newImage) => {
					return main?.mediaPreviewUrl === newImage.url;
				})?.[0];
				id = main?.id;
			}

			if (id) {
				axios
					.put(
						API.picture.setMain,
						{ id },
						{
							headers: {
								"X-Auth-Token": props.token,
								"Content-Type": "application/json",
								accept: "application/json",
							},
						}
					)
					.then((res) => {})
					.catch(console.log)
					.finally(() => {
						fetch(API.picture.edit, {
							method: "PUT",
							headers: { "X-Auth-Token": props.token, "Content-Type": "application/json" },
							body: JSON.stringify({
								id: main.id,
								url: main.contentType === "PHOTO" ? main.mediaPreviewUrl : main.videoThumbnailUrl,
								mediaUrl: main.contentType === "VIDEO" ? main.mediaPreviewUrl : undefined,
								mediaType: main.contentType,
								event: { id: props.event.id },
								main: true,
							}),
						}).then((res) => res.json());
					});
			}
		})
		.catch((err) => {
			console.log(err);
			MLDialog.showSnackbar(err?.message || err, { variant: "error" });
		});
}
